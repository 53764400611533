<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionEdit">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionEdit && !loading">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <router-link :to="{ name: 'Extsource', params: { id: row.id }}">back to external sources page</router-link>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Name</strong></div>
              <div v-if="!edit.name" class="mb-2">
                {{row.name}}
                <b-button variant="outline-primary" size="sm" v-on:click="edit.name = true">Edit</b-button>
              </div>
                <b-form-input v-if="edit.name" id="titleInput"
                                v-model="row.name"
                                placeholder="External source name"
                                required
                                class="mb-2">
                </b-form-input>
                <b-button v-if="validateTitle" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('name')">Save</b-button>
                <b-button v-if="edit.name" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('name')">Cancel</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Definition</strong></div>
              <div v-if="!edit.comments" class="mb-2">
                <markdown :content="row.comments" />
                <b-button variant="outline-primary" size="sm" v-on:click="edit.comments = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.comments" id="detailsInput"
                                v-model="row.comments"
                                placeholder="Comments"
                                required
                                :rows=10
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateComments" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('comments')">Save</b-button>
                <b-button v-if="edit.comments" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('comments')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col>
              <h5>Deleting external source</h5>
              <b-button variant="danger" v-on:click="deleteRow">Delete external source</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'ExtsourceEdit',
  beforeDestroy () {},
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validateComments: function () {
      return this.edit.comments && this.row.comments
    },
    validateLink: function () {
      return this.edit.link && this.row.link
    },
    validateTitle: function () {
      return this.edit.name && this.row.name
    }
  },
  created: function () {
    this.permissionEdit = ac.can(this.user.acgroups).createAny('extsource').granted
    this.load()
  },
  data () {
    return {
      edit: {
        comments: false,
        name: false
      },
      loading: true,
      permissionEdit: false,
      saving: false,
      row: {
        name: '',
        comments: '',
        link: ''
      },
      rowOld: {}
    }
  },
  methods: {
    cancelSaveProperty: function (property) {
      this.$logger.debug('cancel save property')
      this.row[property] = this.rowOld[property]
      this.edit[property] = false
    },
    deleteRow: async function () {
      this.$logger.debug('deleteRow started')
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/extsource`
        let params = {
          body: {
            id: this.row.id
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        this.$router.push({ name: 'Extsources' })
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    load: async function () {
      this.$logger.debug('load started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/extsource/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.rowOld.name = this.row.name
        this.rowOld.comments = this.row.comments
        this.rowOld.link = this.row.link
        this.loading = false
      } catch (e) {
        this.$logger.warn('/extsource/:id error', e)
      }
    },
    saveProperty: async function (property) {
      this.$logger.debug(`saving ${property} start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/property`
        let params = {
          body: {
            property: {
              partition: 'events',
              id: this.row.id,
              propertyName: property,
              propertyValue: this.row[property],
              table: 'extsources'
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.edit[property] = false
        this.rowOld[property] = this.row[property]
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
